// Enable/disable text select
.disable-text-select {
    user-select: none;
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
}

.enable-text-select {
    user-select: auto;
    -moz-user-select: -moz-auto;
    -khtml-user-select: auto;
    -webkit-user-select: auto;
}

// Paddings and margings for iOS full screen devices (with notch)
.pb-env {
    padding-bottom: constant(safe-area-inset-bottom);
    /* iOS 11.0 */
    padding-bottom: env(safe-area-inset-bottom);
    /* iOS 11.2 */
}

.pt-env {
    padding-top: constant(safe-area-inset-bottom);
    /* iOS 11.0 */
    padding-top: env(safe-area-inset-bottom);
    /* iOS 11.2 */
}

.mb-env {
    margin-bottom: constant(safe-area-inset-bottom);
    /* iOS 11.0 */
    margin-bottom: env(safe-area-inset-bottom);
    /* iOS 11.2 */
}

.mt-env {
    margin-top: constant(safe-area-inset-bottom);
    /* iOS 11.0 */
    margin-top: env(safe-area-inset-bottom);
    /* iOS 11.2 */
}

// Disable double tap zoom
body {
    touch-action: manipulation;
}

// Show a pointer for a cursor
.cursor-pointer {
    cursor: pointer;
}

// Show a not allowed cursor
.cursor-not-allowed {
    cursor: not-allowed;
}

// Disable scrolling for element
.no-scroll {
    overflow: hidden;
}

body.no-scroll {
    // keep the scrollbar padding for the body
    @include media-breakpoint-up(md) {
        padding-right: 16px !important;
    }
}

// A div that can be used as an overlay (the parent needs to have position: relative)
.overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
}

// Image: object fit: cover
.object-fit-cover {
    object-fit: cover;
}

// Show/hide without using display: none (otherwise  getBoundingClientRect will not work)
// https://thisthat.dev/display-none-vs-opacity-0-vs-visibility-hidden/
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .smart-hidden#{$infix} {
            visibility: hidden !important;
            overflow: hidden !important;
            height: 0 !important;
            padding: 0 !important;
            margin: 0 !important;
        }

        .smart-visible#{$infix} {
            visibility: visible !important;
            overflow: auto !important;
            height: auto !important;
        }
    }
}